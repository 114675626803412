import Rellax from 'rellax';
import 'sticky-kit/dist/sticky-kit.min.js';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min.js';
import anime from 'animejs';
import ScrollReveal from 'scrollreveal';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export default {
  init() {
    // JavaScript to be fired on all pages

    let menuState = false;
    $('#allFoods').click(function (e) {
      e.preventDefault();
      if (!menuState) {
        $('.hiddenMenuitem').removeClass('hidden');
        $('#allFoods').html('zuklappen');
        menuState = true;
      } else {
        $('.hiddenMenuitem').addClass('hidden');
        $('#allFoods').html('Alle Gerichte anzeigen');
        menuState = false;

        var scroll = new SmoothScroll();
        var anchor = document.querySelector('#speisekarte');
        var options = { offset: 100 };
        scroll.animateScroll(anchor, false, options);
      }
    });

    if (
      window.location.href.indexOf('impressum') > -1 ||
      window.location.href.indexOf('datenschutz') > -1
    ) {
      $('#nav-primary').addClass('legals');
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    var frontPageHeader = document.getElementById('nav-primary');
    var frontPageHeaderHeight = frontPageHeader.offsetHeight;

    $(window).on('scroll', function () {
      var bodyClasses = document.getElementById('body-classes');
      if (bodyClasses.classList.contains('front-page-data')) {
        if ($(window).scrollTop() <= frontPageHeaderHeight - 30) {
          bodyClasses.classList.remove('sticky__menu');
          // headerbar.classList.remove('js__menubar--sticky');
          $('#headerbar').trigger('sticky_kit:detach');
        } else {
          bodyClasses.classList.add('sticky__menu');
          // headerbar.classList.add('js__menubar--sticky');

          $('#headerbar').stick_in_parent({
            bottoming: true,
            offset_top: 0,
          });
        }
      }
    });

    // Smooth Scroll
    new SmoothScroll('a[href*="#"]', {
      updateURL: false,
      popstate: false,
      offset: 100,
      clip: true,
    });

    $('.js__menubar--sticky').stick_in_parent({
      parent: $('html'),
      bottoming: true,
      offset_top: 0,
      spacer: false,
    });

    function revealElements() {
      ScrollReveal().reveal('.reveal', {
        delay: 200,
        duration: 450,
        distance: '16px',
        interval: 50,
        easing: 'cubic-bezier(0.5, 0, 0, 1)',
      });
    }

    revealElements();

    // Mobile Menu
    $(document)
      .off('click', '.js__openmenu')
      .on('click', '.js__openmenu', function (e) {
        e.preventDefault();
        togglemenu();
      });

    // toggle Menu - toggle bodyclass
    function togglemenu() {
      if ($('#body-classes').hasClass('mobilemenu--open')) {
        anime({
          targets: '#menu-mainmenu > li',
          translateX: [0, 100],
          opacity: [1, 0],
          easing: 'easeInOutQuad',
          loop: false,
          duration: 100,
          delay: anime.stagger(50),
          complete: function () {
            $('#body-classes').removeClass('mobilemenu--open');
            $('html').removeClass('noscroll');
          },
        });
      } else {
        $('#body-classes').addClass('mobilemenu--open');
        $('html').addClass('noscroll');

        anime({
          targets: '#menu-mainmenu > li',
          translateX: [-288, 0],
          opacity: [0, 1],
          easing: 'easeInOutQuad',
          loop: false,
          duration: 300,
          delay: anime.stagger(100),
          complete: function () {},
        });
      }
      return false;
    }

    // Slider Employee Block
    var sliderLunch = document.querySelectorAll(
      '.swiper-container.sliderLunch'
    );

    var sliderSlidesAmount = document.querySelectorAll(
      '.swiper-container.sliderLunch .swiper-slide'
    );

    if (sliderLunch && sliderSlidesAmount.length !== 1) {
      sliderLunch.forEach(function (sliderLunch) {
        let lunchSwiper = new Swiper(sliderLunch, {
          direction: 'horizontal',
          freeMode: false,
          speed: 1000,
          delay: 1000,
          slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });
        lunchSwiper.update();
      });
    }

    // JavaScript to be fired on all pages, after page specific JS is fired
    $(window).on('load resize', function () {
      if ($(window).width() >= 768) {
        $('#body-classes').removeClass('mobilemenu--open');
        $('html').removeClass('noscroll');
      }

      // update all swipers on load or resize
      // const swiper = document.querySelectorAll('.swiper');
      // if (swiper.length) {
      //   Array.from(swiper).forEach((element) => {
      //     element.swiper.update();
      //   });
      // }
    });

    $(document).ready(function () {
      new Rellax('.rellax');
    });

    // remove the mobilemenu--open and noscroll class if mobileview on mobilemenulink click

    if ($(window).width() <= 768) {
      $('body').on('click', '.menu-item a', function () {
        $('#body-classes').removeClass('mobilemenu--open');
        $('html').removeClass('noscroll');
      });
    }

    function getYPosition() {
      var top = window.pageYOffset || document.documentElement.scrollTop;
      return top;
    }

    function highLightMenuItemsOnScroll() {
      const menuItemsAnchors = $('#menu-mainmenu a');
      const offsetScroll = 120;

      $.each(menuItemsAnchors, function (key, value) {
        var menuhref = $(value).attr('href');
        var anchor = menuhref.replace('/', '');

        if (anchor.startsWith('http')) {
          return true;
        }

        if (getYPosition() >= $(anchor).offset().top - offsetScroll) {
          $('#menu-mainmenu a').parent().removeClass('active');

          $('#menu-mainmenu a[href="' + menuhref + '"]')
            .parent()
            .addClass('active');
        }
      });
    }

    $(window).on('scroll', function () {
      highLightMenuItemsOnScroll();
    });

    if ($('.swiper-container.swiper-header').length != 0) {
      // Slider Header Block
      var sliderheaderContainers = document.querySelectorAll(
        '.swiper-container.swiper-header'
      );

      if (sliderheaderContainers) {
        sliderheaderContainers.forEach(function (sliderheaderContainer) {
          let headerSwiper = new Swiper(sliderheaderContainer, {
            modules: [EffectFade],
            effect: 'fade',
            loop: true,
            direction: 'horizontal',
            autoplay: true,
            speed: 5000,
            delay: 5000,
            slidesPerView: 1,
          });

          headerSwiper.update();
        });
      }
    }
  },
};
