// import external dependencies
import 'jquery';
import barbaInit from './barba/init';

// Import everything from autoload
;

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import blockMap from './routes/map';

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  blockMap,
});

// Load Events
jQuery(document).ready(() => {
  routes.loadEvents();
  barbaInit(routes);
});
