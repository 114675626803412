import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

export default {
  init() {
    function buildMap() {
      // init Map
      mapboxgl.accessToken =
        ajax_object.access_token;

      var map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/alpha-alpaka/ckvtblfza1utu15nvqflcuio1',
        zoom: 16,
        pitch: 0,
        center: [8.20151, 48.85839],
      });

      map.scrollZoom.disable();

      // Add a marker to the map for every store listing.
      function addMarkers() {
        const markerRastatt = document.createElement('div');
        markerRastatt.className = 'marker w-5 h-5 icon--medium icon--mapmarker';

        // Rastatt
        new mapboxgl.Marker(markerRastatt)
          .setLngLat([8.20151, 48.85839])
          .addTo(map);
      }

      map.on('load', function () {
        /**
         * This is where your '.addLayer()' used to be, instead
         * add only the source without styling a layer
         */

        // map.addControl(
        //   new mapboxgl.NavigationControl({ compact: true }),
        //   'bottom-right'
        // );

        /**
         * Add all the things to the page:
         * - The location listings on the side of the page
         * - The markers onto the map
         */
        addMarkers();
      });
    }

    buildMap();
  },
};
